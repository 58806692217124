import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = (props) => {
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">{props.breadcrumbItem}</h4>
          <div className="page-title-right">
            <ol className={`breadcrumb m-0 ${props.actionBtn ? 'align-items-center' : ''}`}>
              {props.actionBtn && (
                <button
                  type="submit"
                  style={{
                    height: '20px',
                    lineHeight: '0px',
                    fontSize: '12px',
                    borderRadius: '4px',
                    backgroundColor: props.actionBtnLoading ? '#7da8e6' : '#556EE6', // Use different colors for loading 
                    color: '#fff',
                    cursor: 'pointer',
                    padding: '0px 6px',
                    border: 'none',
                    // Conditional style for disabled state
                    ...(props.actionBtnDisabled && { backgroundColor: '#d3d3d3', cursor: 'not-allowed' }),
                  }}
                  onClick={props.actionBtnFunction}
                  disabled={props.actionBtnDisabled}
                >
                  {props.actionBtnLoading ? (
                    <>{props.actionBtn} <i className="fa fa-spinner fa-pulse"></i></>
                  ) : (
                    `${props.actionBtn}`
                  )}
                </button>

              )}
              <BreadcrumbItem style={{ margin: '0 5px' }}>
                <Link to="/dashboard">{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active style={{ margin: '0 5px' }}>
                <Link to={(props?.itemLink && props?.itemLink?.length) ? props?.itemLink : "#"}>{props.breadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};




Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  actionBtn: PropTypes.string,
  actionBtnFunction: PropTypes.func,
  actionBtnDisabled: PropTypes.bool,
  actionBtnLoading: PropTypes.bool,
  itemLink: PropTypes.string,
}

export default Breadcrumb
