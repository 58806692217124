import PropTypes from "prop-types"
import React, { useState } from "react"
import { Modal, ModalBody } from "reactstrap"

const DeleteModal = ({
  show,
  onDeleteClick,
  onCloseClick,
  itemName,
  onCheckBoxChange,
  setReasonText,
}) => {
  return (
    <Modal size="sm" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          {itemName !== "Booking" ? (
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-trash-can-outline"></i>
              </div>
            </div>
          ) : (
            ""
          )}
          {itemName !== "Booking" ? (
            <p className="text-muted font-size-16 mb-4">
              Are you sure you want to permanently delete the{" "}
              {itemName || "item"}.
            </p>
          ) : (
            <>
              <textarea
                placeholder="Reason of cancellation"
                rows={4} // Set the number of rows as needed
                cols={20} // Set the number of columns as needed
                onChange={e => {
                  setReasonText(e.target.value)
                }}
              />
              <br />
              <label>
                Refund the amount to Customer:
                <input
                  type="checkbox"
                  className="form-check-input border-2 mx-2"
                  onChange={onCheckBoxChange}
                />
              </label>
            </>
          )}

          <div className="hstack gap-2 justify-content-center mb-0">
            {itemName !== "Booking" ? (
              <>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={onDeleteClick}
                >
                  Delete Now
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onCloseClick}
                >
                  Close
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={onDeleteClick}
                >
                  Submit
                </button>
              </>
            )}
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
}

export default DeleteModal
