import {
  SET_NAV_TITLE
} from "./actionTypes";

const NavTitle = (state = '', action) => {
  switch (action.type) {
    case SET_NAV_TITLE:
      return action.payload

    default:
      return state;
  }
};

export default NavTitle;