const TimeStampToDate = ({ timestamp }) => {
  const date = new Date(timestamp * 1000)

  const year = date.getFullYear()
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  const month = monthNames[date.getMonth()]
  const day = ("0" + date.getDate()).slice(-2)

  let hours = date.getHours()
  const minutes = ("0" + date.getMinutes()).slice(-2)
  const seconds = ("0" + date.getSeconds()).slice(-2)

  const ampm = hours >= 12 ? "PM" : "AM"
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  const formattedHours = ("0" + hours).slice(-2)

  const dateTime = `${month}-${day}-${year} ${formattedHours}:${minutes} ${ampm}`

  return dateTime
}

export default TimeStampToDate
