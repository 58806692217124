import { readableDate } from "methods/globalMethods"
import React from "react"
import { Link } from "react-router-dom"
import { Badge } from "reactstrap"

const Id = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell.value ? cell.value : ""}
    </Link>
  )
}

const FirstName = cell => {
  return cell.value ? cell.value : ""
}

const LastName = cell => {
  return cell.value ? cell.value : ""
}

const UserName = cell => {
  return cell.value ? cell.value : ""
}

const Email = cell => {
  return cell.value ? cell.value : ""
}

const Role = cell => {
  return cell.value ? cell.value : ""
}

const Region = cell => {
  return cell.value ? cell.value : ""
}
const Blocked = cell => {
  return (
    <Badge
      className={
        "font-size-12 badge-soft-" + (cell.value == "" ? "danger" : "success")
      }
    >
      {cell.value ? "Yes" : "No"}
    </Badge>
  )
}

const Status = cell => {
  return (
    <Badge
      className={
        "font-size-12 badge-soft-" +
        (cell.value && cell.value == "active"
          ? "success"
          : cell.value == "pending"
          ? "warning"
          : "danger")
      }
    >
      {cell.value}
    </Badge>
  )
}

const EmailStatus = cell => {
  return (
    <Badge
      className={
        "font-size-12 badge-soft-" +
        (cell.value && cell.value == "Verified"
          ? "success"
          : cell.value == "UnVerified"
          ? "warning"
          : "danger")
      }
    >
      {cell.value}
    </Badge>
  )
}

const Address = cell => {
  return cell.value ? cell.value : "No Location."
}

// const Rating = (cell) => {
//     return (
//         <span className="bg-success font-size-12 badge bg-success"><i className="mdi mdi-star me-1"></i>{cell.value}</span>
//     )
// };

// const WalletBalances = (cell) => {
//     return cell.value ? cell.value : '';
// };

const JoiningDate = cell => {
  return cell.value ? cell.value : ""
}

export {
  Id,
  FirstName,
  LastName,
  UserName,
  Email,
  Role,
  Status,
  Address,
  Region,
  // Rating,
  // WalletBalances,
  JoiningDate,
  Blocked,
  EmailStatus,
}
