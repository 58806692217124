import axios from "axios"
import {
  clearLocalStorage,
  getLocalAccessToken,
  updateLocalAccessToken,
} from "./token.service"

let refreshTries = 0
const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
})

instance.interceptors.request.use(
  config => {
    const token = getLocalAccessToken()
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}` // for Node.js Express back-end
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  res => {
    return res
  },
  async err => {
    const originalConfig = err.config
    if (originalConfig.url !== "/api/auth/sign-in" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        try {
          //it will try two times to update token using refresh token cookie, on thisrd try it will clear old
          //token and redirect user to the login page
          if (refreshTries >= 2) {
            clearLocalStorage()
            window.location.href = "/sign-in"
          } else {
            window.location.href = "/sign-in"
          }
        } catch (_error) {
          console.log("_error", _error)
          return Promise.reject(_error)
        }
      }
    }

    return Promise.reject(err)
  }
)

export const logAxiosError = error => {
  if (error.response) {
    // Request made and server responded
    console.log(error.response.data)
    console.log(error.response.status)
    console.log(error.response.headers)
    // setError(error.response.data)
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request)
    // setError('No response was received' + error.request)
  } else {
    console.log("this  option is triggered")
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message)
    // setError('Could not make an API call => ' + error.message)
  }
}

export default instance
