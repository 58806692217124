import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "../../components/Common/DeleteModal"
import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "store/e-commerce/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../components/Common/TableContainer"

// Column
import {
  Id,
  FirstName,
  LastName,
  Email,
  // Address,
  // Rating,
  // WalletBalances,
  JoiningDate,
  Role,
  Blocked,
  Status,
  UserName,
} from "./UserCol"
import defaultTableOptions from "constants/tableOptions"

import AxiosInstance, { logAxiosError } from "services/axios_service"
import { readableDate } from "methods/globalMethods"

const Users = props => {
  //meta title
  document.title = "Users | Measure Admin & Dashboard"

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { customers } = useSelector(state => ({
    customers: state.ecommerce.customers,
  }))

  const [tableOptions, setTableOptions] = useState(defaultTableOptions)
  const [tableData, setTableData] = useState([])
  const [isTableLoading, setTableIsLoading] = useState(false)
  const [updatePage, setUpdatePage] = useState(true)
  const [blockCounter, setBlockCounter] = useState(1)
  const [isEdit, setIsEdit] = useState(false)
  const [modal, setModal] = useState(false)
  const [saveUserBtnLoading, setSaveUserBtnLoading] = useState(false)

  const [filter, setFilter] = useState({
    roles: "",
    isBanned: "",
  })

  const [selectedItemId, setSelectedItemId] = useState(null)

  const [userModalFields, setUserModalFields] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    password1: "",
    password2: "",
    roles: "user",
  })

  const [errors, setErrors] = useState([])

  // Module Column
  const columns = useMemo(
    () => [
      // {
      //   Header: '#',
      //   Cell: () => {
      //     return <input type="checkbox" className="form-check-input" />;
      //   }
      // },
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        Cell: cellProps => {
          return <Id {...cellProps} />
        },
      },
      // {
      //   Header: 'First Name',
      //   accessor: 'firstName',
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <FirstName {...cellProps} />;
      //     ;
      //   }
      // },
      // {
      //   Header: 'Last Name',
      //   accessor: 'lastName',
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <LastName {...cellProps} />;
      //     ;
      //   }
      // },
      {
        Header: "Name",
        accessor: "userName",
        filterable: true,
        Cell: cellProps => {
          return <UserName {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />
        },
      },
      {
        Header: "Role",
        accessor: "roles",
        filterable: true,
        Cell: cellProps => {
          return <Role {...cellProps} />
        },
      },
      // {
      //   Header: 'Blocked',
      //   accessor: 'isBanned',
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <Blocked {...cellProps} />;
      //     ;
      //   }
      // },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },

      // {
      //   Header: 'Status',
      //   accessor: 'status',
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <Status {...cellProps} />;
      //   }
      // },
      {
        Header: "Joining Date",
        accessor: "createdAt",
        filterable: true,
        Cell: cellProps => {
          return <JoiningDate {...cellProps} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => {
                    const customerData = cellProps.row.original
                    navigate(`/customers/${customerData?.id}`, {
                      state: { parent: "/customers" },
                    })
                  }}
                >
                  <i
                    className="mdi mdi-eye font-size-16 me-1"
                    id="viewtooltip"
                  ></i>
                  View
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    getUserDetails(cellProps.row.original?.id)
                  }}
                >
                  <i
                    className="mdi mdi-pencil font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>
                  Edit
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    setSelectedItemId(cellProps.row.original?.id)
                    setDeleteModal(true)
                  }}
                >
                  <i
                    className="mdi mdi-trash-can font-size-16 text-danger me-1"
                    id="deletetooltip"
                  ></i>
                  Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    console.log(
                      "%cindex.js line:228 { id: cellProps.row.original?.id, isBanned: cellProps.row.original?.isBanned ? false : true }",
                      "color: #007acc;",
                      {
                        id: cellProps.row.original?.id,
                        isBanned: cellProps.row.original?.isBanned
                          ? false
                          : true,
                      }
                    )
                    blockUnblockToggle({
                      id: cellProps.row.original?.id,
                      isBanned: cellProps.row.original?.isBanned ? false : true,
                    })
                  }}
                >
                  {cellProps.row.original?.isBanned ? (
                    <i
                      className="mdi mdi-lock-open font-size-16 text-danger me-1"
                      id="blocktoggle"
                    ></i>
                  ) : (
                    <i
                      className="mdi mdi-lock font-size-16 text-danger me-1"
                      id="blocktoggle"
                    ></i>
                  )}
                  {cellProps.row.original?.isBanned ? "Unblock" : "Block"}
                  <UncontrolledTooltip placement="top" target="blocktoggle">
                    {cellProps.row.original?.isBanned ? "Unblock" : "Block"}
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    []
  )

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const deleteItem = () => {
    AxiosInstance.delete(`/api/admin/users/${selectedItemId}`, {
      data: { password: "password" },
    })
      .then(response => {
        setUpdatePage(!updatePage)
        setDeleteModal(false)
      })
      .catch(error => {
        logAxiosError(error)
        alert(error.response?.data?.message)
      })
  }

  useEffect(() => {
    setTableIsLoading(true)
    setTableData([])

    AxiosInstance.get("/api/admin/users", {
      params: { ...tableOptions, ...filter },
    })
      .then(response => {
        setTableOptions({ ...tableOptions, ...response.data?.meta })
        response?.data?.data?.map(item => {
          item.createdAt = readableDate(item.createdAt)
          item.roles = item.roles == "user" ? "candidate" : item.roles
          item.userName = item.firstName + " " + item.lastName
          item.status = item.isBanned ? "Blocked" : item.status
        })
        setTableData(response.data?.data)
        setTableIsLoading(false)
      })
      .catch(error => {
        logAxiosError(error)
      })
  }, [updatePage, blockCounter, filter])

  function handleUserModalChange(evt) {
    const value = evt.target.value
    setUserModalFields({
      ...userModalFields,
      [evt.target.name]: value,
    })
  }

  function getUserDetails(id) {
    AxiosInstance.get(`/api/admin/users/${id}`, { params: {} })
      .then(response => {
        // setQuestionFields({ ...response.data, description: JSON.parse(response.data?.description) })
        setUserModalFields(response.data)
        setIsEdit(true)
        toggle()
      })
      .catch(error => {
        logAxiosError(error)
      })
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setUserModalFields({
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        password1: "",
        password2: "",
        roles: "user",
      })
      setErrors([])
      // setselectedFiles([])
    } else {
      setModal(true)
    }
  }

  const creatUser = async () => {
    setSaveUserBtnLoading(true)
    let cleaObj = userModalFields
    // cleaObj = removeEmpty(cleaObj)
    // cleaObj.description = JSON.stringify(cleaObj.description)
    // const { link, type } = await uploadQuestionImage()
    // console.log('%cEditModule.js line:270 link, type', 'color: #007acc;', link, type);

    AxiosInstance.post(`/api/admin/users/create`, { ...cleaObj })
      .then(response => {
        // setSaveBtnLoading(false)
        toggle()
        setUpdatePage(!updatePage)
        setSaveUserBtnLoading(false)
        setErrors([])
      })
      .catch(error => {
        logAxiosError(error)
        setErrors(error.response.data.message)
        setSaveUserBtnLoading(false)
      })
  }

  const saveUser = async () => {
    setSaveUserBtnLoading(true)
    let cleaObj = userModalFields
    // cleaObj = removeEmpty(cleaObj)
    // cleaObj.description = JSON.stringify(cleaObj.description)
    // const { link, type } = await uploadQuestionImage()
    // setSaveBtnLoading(true)
    AxiosInstance.patch(
      `/api/admin/users/${userModalFields?.id}`,
      cleaObj
      //  { ...cleaObj, description, ...((!selectedFiles[0] && !cleaObj.image) ? { image: " " } : { image: link }) }
    )
      .then(response => {
        // setSaveBtnLoading(false)
        toggle()
        setUpdatePage(!updatePage)
        setSaveUserBtnLoading(false)
        setErrors([])
      })
      .catch(error => {
        logAxiosError(error)
        setErrors(error.response.data.message)
        setSaveUserBtnLoading(false)
      })
  }

  const blockUnblockToggle = async object => {
    AxiosInstance.patch(
      `/api/admin/users/${object?.id}`,
      object
      //  { ...cleaObj, description, ...((!selectedFiles[0] && !cleaObj.image) ? { image: " " } : { image: link }) }
    )
      .then(response => {
        setBlockCounter(blockCounter + Math.random())
      })
      .catch(error => {
        logAxiosError(error)
        alert(error.response?.data?.message)
      })
  }

  function removeEmpty(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null && v !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={deleteItem}
        onCloseClick={() => setDeleteModal(false)}
        itemName="User"
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Measure" breadcrumbItem="Users" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={tableData}
                    isGlobalFilter={true}
                    isAddUserListServer={true}
                    handleUserClickServer={() => {
                      setIsEdit(false)
                      toggle()
                      // navigate("/create-module", {/*params*/ })
                    }}
                    customPageSize={tableOptions?.take}
                    className="custom-header-css"
                    isLoading={isTableLoading}
                    tableOptions={tableOptions}
                    setTableOptions={setTableOptions}
                    updatePage={updatePage}
                    setUpdatePage={setUpdatePage}
                    userStatusFilter={true}
                    setFilter={setFilter}
                    filter={filter}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            isOpen={modal}
            toggle={toggle}
            style={{ width: "75%" }}
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit User" : "Add User"}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <Row className="mb-3">
                    <Col className="col-6">
                      <div className="">
                        <Label className="form-label">First Name</Label>
                        <Input
                          name="firstName"
                          type="text"
                          placeholder="Enter first name"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.firstName}
                        />
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="">
                        <Label className="form-label">Last Name</Label>
                        <Input
                          name="lastName"
                          type="text"
                          placeholder="Enter last name"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.lastName}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-6">
                      <div className="">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          type="text"
                          placeholder="Enter email"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.email}
                        />
                      </div>
                    </Col>
                  </Row>

                  {!isEdit && (
                    <Row className="mb-3">
                      <Col className="col-6">
                        <div className="">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password1"
                            type="text"
                            placeholder="Enter password"
                            onChange={handleUserModalChange}
                            // onBlur={validation.handleBlur}
                            value={userModalFields?.password1}
                          />
                        </div>
                      </Col>
                      <Col className="col-6">
                        <div className="">
                          <Label className="form-label">Confirm Password</Label>
                          <Input
                            name="password2"
                            type="text"
                            placeholder="Re-enter password"
                            onChange={handleUserModalChange}
                            // onBlur={validation.handleBlur}
                            value={userModalFields?.password2}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}

                  <div className="form-floating mb-3">
                    {/* {fields?.status == "active" ? "0" : fields?.status == "draft" ? "1" : "2"} */}
                    <select
                      value={userModalFields?.roles}
                      className="form-select"
                      name="roles"
                      onChange={handleUserModalChange}
                    >
                      <option value="user">Candidate</option>
                      <option value="recruiter">Recruiter</option>
                      <option value="admin">Admin</option>
                    </select>
                    <label htmlFor="floatingSelectGrid">Role</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      className="btn btn-success save-customer"
                      onClick={isEdit ? saveUser : creatUser}
                    >
                      {saveUserBtnLoading ? (
                        <i className="fa fa-spinner fa-pulse"></i>
                      ) : (
                        (isEdit ? "Save" : "Create") + " User"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
              {errors[0] && (
                <Row className="mt-3">
                  <Col>
                    <Alert color="danger" role="alert">
                      {errors[0] && errors[0]}
                    </Alert>
                  </Col>
                </Row>
              )}
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

// Users.propTypes = {
//   customers: PropTypes.array,
//   onGetCustomers: PropTypes.func,
//   onAddNewCustomer: PropTypes.func,
//   onDeleteCustomer: PropTypes.func,
//   onUpdateCustomer: PropTypes.func,
// };

export default Users
