import React, { Fragment, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { LiaSortDownSolid, LiaSortSolid, LiaSortUpSolid } from "react-icons/lia"
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Filter, DefaultColumnFilter, SelectColumnFilter } from "./filters"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
import ReactLoading from "react-loading"
import { useDispatch, useSelector } from "react-redux"
import { setTableStates } from "store/table_states/actions"
import { readableDate } from "methods/globalMethods"
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
  isServerSidedSearch,
  tableOptions,
  setTableOptions,
  updatePage,
  setUpdatePage,
  isPreserved,
}) {
  const dispatch = useDispatch()
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const preserved = useSelector(state => state.TableStates)
  const onChange = useAsyncDebounce(value => {
    isServerSidedSearch
      ? (() => {
          setTableOptions({ ...tableOptions, search: value })
          dispatch(setTableStates({ ...tableOptions, search: value }))
          setUpdatePage(!updatePage)
        })()
      : setGlobalFilter(value || undefined)
  }, 500)
  return (
    <React.Fragment>
      <Col md={8}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                {isServerSidedSearch ? "Search" : "Search this table"}
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${
                  isServerSidedSearch ? tableOptions.itemCount : count
                } records...`}
                value={value || (isPreserved && preserved?.search) || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isJobListGlobalFilter && <JobListGlobalFilter />}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  isAddUserListServer,
  handleUserClickServer,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  handleModuleClick,
  handleAdvancedModuleClick,
  isAddQuestionsList,
  handleQuestionClick,
  is_sample_question,
  isAddCategoryList,
  handleCategoryClick,
  isAddCustList,
  isAddModuleList,
  customPageSize,
  className,
  customPageSizeOptions,
  isLoading,
  tableOptions,
  setTableOptions,
  updatePage,
  setUpdatePage,
  isAddPackageList,
  handlePackageClick,
  userStatusFilter,
  setFilter,
  filter,
  isPreserved,
  isAddInvoiceList,
  invoiceStatusFilter,
  handleInvoiceClick,
  userType,
  isAssessmentListing,
  showQuestionDownloadBtn,
  handleClickQuestionsDownload,
  showQuestionUploadBt,
  handleClickQuestionsUpload,
  isSelectingQuestions,
  setIsSelectingQuestions,
  showSelectionButton,
  showSampleSelectionButton,
  isSelectingSampleQuestions,
  setIsSelectingSampleQuestions,
  actionOnSelectedQuestions,
  isAddJobList,
  handleJobClick,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        // sortBy: [
        //   {
        //     asc: true,
        //   },
        // ],
      },
      // Add a custom sorting method for each column
      sortTypes: {
        alphanumeric: (rowA, rowB, columnId) => {
          if (columnId === "createdAt") {
            const valueA = new Date(rowA.values[columnId])
            const valueB = new Date(rowB.values[columnId])
            // Compare dates directly
            return valueA - valueB
          }

          const valueA = rowA.values[columnId]
          const valueB = rowB.values[columnId]

          // Handle boolean values separately
          if (typeof valueA === "boolean" && typeof valueB === "boolean") {
            return valueA === valueB ? 0 : valueA ? -1 : 1
          }

          // Handle numeric values
          if (typeof valueA === "number" && typeof valueB === "number") {
            return valueA - valueB
          }

          // Handle string values
          if (typeof valueA === "string" && typeof valueB === "string") {
            return valueA.localeCompare(valueB, undefined, {
              sensitivity: "base",
            })
          }

          // For other types, use default sorting
          return 0
        },
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )
  const [selectedDate, setSelectedDate] = useState(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)
  const generateSortingIndicator = column => {
    return column?.id == "Action" ? (
      ""
    ) : column.isSorted ? (
      column.isSortedDesc ? (
        <LiaSortDownSolid />
      ) : (
        <LiaSortUpSolid />
      )
    ) : (
      <LiaSortSolid />
    )
    // return column?.id == 'Action' ? '' : (column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : " 🔼 🔽");
    // return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : " 🔼 🔽";
  }

  const onChangeInSelect = event => {
    tableOptions
      ? (() => {
          setTableOptions({ ...tableOptions, take: Number(event.target.value) })
          dispatch(
            setTableStates({
              ...tableOptions,
              take: Number(event.target.value),
            })
          )
          setUpdatePage(!updatePage)
          setPageSize(Number(event.target.value))
        })()
      : setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    tableOptions
      ? (() => {
          setTableOptions({ ...tableOptions, page: page + 1 })
          dispatch(setTableStates({ ...tableOptions, page: Number(page) + 1 }))
          setUpdatePage(!updatePage)
        })()
      : gotoPage(page)
  }
  const dispatch = useDispatch()

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d)
  }

  function getDateInMySQLFormat(d) {
    const year = d?.getFullYear()
    let month = d?.getMonth() + 1
    if (month < 10) month = "0" + month
    let date = d?.getDate()
    if (date < 10) date = "0" + date

    return `${year}-${month}-${date}`
  }

  return (
    <Fragment>
      {userStatusFilter && !window.location.href.includes("leads") && (
        <Row>
          <div className="d-flex flex-wrap gap-2 mb-3">
            <Button
              color={Object.entries(filter).length == 0 ? "success" : "light"}
              className="btn-rounded "
              onClick={() => setFilter({})}
            >
              All
            </Button>
            <Button
              color={filter?.isBanned == "true" ? "success" : "light"}
              className="btn-rounded"
              onClick={() =>
                setFilter({
                  isBanned: "true",
                  roles: userType == "user" ? "user" : "recruiter",
                })
              }
            >
              Blocked
            </Button>

            <Button
              color={filter?.status == "active" ? "success" : "light"}
              className="btn-rounded"
              onClick={() =>
                setFilter({
                  status: "active",
                  roles: userType == "user" ? "user" : "recruiter",
                })
              }
            >
              Active
            </Button>

            <Button
              color={filter?.status == "pending" ? "success" : "light"}
              className="btn-rounded"
              onClick={() =>
                setFilter({
                  status: "pending",
                  roles: userType == "user" ? "user" : "recruiter",
                })
              }
            >
              Pending
            </Button>

            <Button
              color={filter?.confirmed ? "success" : "light"}
              className="btn-rounded"
              onClick={() =>
                setFilter({
                  confirmed: true,
                  roles: userType == "user" ? "user" : "recruiter",
                })
              }
            >
              Verified
            </Button>

            <Button
              color={filter?.confirmed == false ? "success" : "light"}
              className="btn-rounded"
              onClick={() =>
                setFilter({
                  confirmed: false,
                  roles: userType == "user" ? "user" : "recruiter",
                })
              }
            >
              Unverified
            </Button>
          </div>
        </Row>
      )}

      {invoiceStatusFilter && (
        <Row>
          <div className="d-flex flex-wrap gap-2 mb-3">
            <Button
              color={
                filter?.status == "" || filter?.status == null
                  ? "success"
                  : "light"
              }
              className="btn-rounded "
              onClick={() => setFilter({ status: "" })}
            >
              All
            </Button>

            <Button
              color={filter?.status == "draft" ? "success" : "light"}
              className="btn-rounded "
              onClick={() => setFilter({ status: "draft" })}
            >
              Draft
            </Button>
            <Button
              color={filter?.status == "open" ? "success" : "light"}
              className="btn-rounded "
              onClick={() => setFilter({ status: "open" })}
            >
              Open
            </Button>
            <Button
              color={filter?.status == "paid" ? "success" : "light"}
              className="btn-rounded "
              onClick={() => setFilter({ status: "paid" })}
            >
              Paid
            </Button>
            <Button
              color={filter?.status == "void" ? "success" : "light"}
              className="btn-rounded "
              onClick={() => setFilter({ status: "void" })}
            >
              Void
            </Button>
            <Button
              color={filter?.status == "uncollectible" ? "success" : "light"}
              className="btn-rounded "
              onClick={() => setFilter({ status: "uncollectible" })}
            >
              Uncollectible
            </Button>
          </div>
        </Row>
      )}

      <Row className="mb-2">
        <Col sm={isAddQuestionsList && showQuestionDownloadBtn ? "2" : "3"}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[10, 20, 30, 40, 50, 100].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <Col sm={isAddQuestionsList && showQuestionDownloadBtn ? "4" : "5"}>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              isJobListGlobalFilter={isJobListGlobalFilter}
              isServerSidedSearch={tableOptions ? true : false}
              tableOptions={tableOptions}
              setTableOptions={setTableOptions}
              setUpdatePage={setUpdatePage}
              updatePage={updatePage}
              isPreserved={isPreserved}
            />
          </Col>
        )}
        {isAddOptions && (
          <Col sm="4">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Order
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="4">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="btn mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="4">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
        {isAddModuleList && (
          <Col sm="4">
            <div className="text-sm-end">
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                  caret
                  color="success"
                  className="btn-rounded mb-2 me-2"
                >
                  <i className="mdi mdi-plus me-1" />
                  New Modules
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={handleModuleClick}>
                    New module from scratch
                  </DropdownItem>
                  <DropdownItem onClick={handleAdvancedModuleClick}>
                    Merge and create new module
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </Col>
        )}
        {isAddInvoiceList && (
          <Col sm="4">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleInvoiceClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Invoice
              </Button>
            </div>
          </Col>
        )}
        {isAddQuestionsList && showQuestionDownloadBtn ? (
          <Col
            sm={"6"}
            className={"d-flex justify-content-end align-items-center"}
          >
            {isAddQuestionsList && (
              <Button
                type="button"
                color="success"
                className="btn btn-success btn-rounded mb-2 me-2"
                onClick={handleQuestionClick}
              >
                <i className="mdi mdi-plus me-1" />
                {"New Question"}
              </Button>
            )}
            {showQuestionDownloadBtn && (
              <Button
                type="button"
                color="success"
                className="btn btn-success btn-rounded mb-2 me-2"
                onClick={handleClickQuestionsDownload}
              >
                <i className="fa fa-download me-1" />
                {"Export XLSX"}
              </Button>
            )}
            {showQuestionUploadBt && (
              <div style={{ position: "relative", overflow: "hidden" }}>
                <input
                  id="file-upload"
                  type="file"
                  onChange={handleClickQuestionsUpload}
                  accept=".xlsx"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    margin: 0,
                    padding: 0,
                    fontSize: "100px",
                    cursor: "cursor",
                    opacity: 0,
                    filter: "alpha(opacity=0)",
                  }}
                />
                <Button
                  type="button"
                  color="success"
                  className="btn btn-success btn-rounded mb-2 me-2"
                  onClick={handleClickQuestionsUpload}
                >
                  <i className="fa fa-upload me-1" />
                  {"Import XLSX"}
                </Button>
              </div>
            )}
            {showSelectionButton && (
              <Button
                type="button"
                color={isSelectingQuestions ? "warning" : "success"}
                className="btn btn-success btn-rounded mb-2 me-2"
                onClick={() => {
                  !isSelectingQuestions &&
                    setIsSelectingQuestions(!isSelectingQuestions)
                  isSelectingQuestions && actionOnSelectedQuestions(true)
                }}
              >
                {isSelectingQuestions
                  ? "Move to Sample Quest.."
                  : "Select Questions"}
              </Button>
            )}

            {showSampleSelectionButton && (
              <Button
                type="button"
                color={isSelectingSampleQuestions ? "warning" : "success"}
                className="btn btn-success btn-rounded mb-2 me-2"
                onClick={() => {
                  !isSelectingSampleQuestions &&
                    setIsSelectingSampleQuestions(!isSelectingSampleQuestions)
                  isSelectingSampleQuestions && actionOnSelectedQuestions(false)
                }}
              >
                {isSelectingSampleQuestions
                  ? "Move to Questions"
                  : "Select Questions"}
              </Button>
            )}
          </Col>
        ) : null}

        {isAddCategoryList && (
          <Col sm="4">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleCategoryClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Industry
              </Button>
            </div>
          </Col>
        )}

        {isAddUserListServer && !window.location.href.includes("leads") && (
          <Col sm="4">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-2"
                onClick={handleUserClickServer}
              >
                <i className="mdi mdi-plus me-1" />
                New User
              </Button>
            </div>
          </Col>
        )}

        {}

        {isAddPackageList && (
          <Col sm="4">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handlePackageClick}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Package
              </Button>
            </div>
          </Col>
        )}

        {isAddJobList && (
          <Col sm="4">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleJobClick}
              >
                <i className="mdi mdi-plus me-1" />
                Add New Job
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div
        className="table-responsive react-table"
        style={{ overflow: "visible" }}
      >
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    key={column.id}
                    {...column.getHeaderProps({
                      ...(column?.Header == "ID" && {
                        style: { width: "6rem" },
                      }),
                    })}
                  >
                    <div
                      className={!column?.canFilter ? "" : "mb-2"}
                      {...column.getSortByToggleProps()}
                      style={
                        tableOptions && !column?.canFilter
                          ? { marginBottom: "1.6rem" }
                          : {}
                      }
                    >
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {tableOptions &&
                    column?.canFilter &&
                    column?.id == "status" ? (
                      <select
                        // value={userModalFields?.roles}
                        className="form-select"
                        style={{ minWidth: "5rem" }}
                        name="status"
                        onChange={e => {
                          if (e.target.value == "blocked") {
                            setTableOptions({
                              ...tableOptions,
                              status: null,
                              isBanned: true,
                            })
                          } else {
                            setTableOptions({
                              ...tableOptions,
                              status: e.target.value,
                            })
                          }
                          setUpdatePage(!updatePage)
                        }}
                      >
                        <option style={{ display: "none" }}>Select</option>

                        {!invoiceStatusFilter && (
                          <option value="active">Active</option>
                        )}
                        {!invoiceStatusFilter && (
                          <option value="pending">Pending</option>
                        )}

                        {userStatusFilter && (
                          <option value="blocked">Blocked</option>
                        )}
                        {isAssessmentListing && (
                          <option value="archived">Archived</option>
                        )}

                        {(isAssessmentListing || invoiceStatusFilter) && (
                          <option value="draft">Draft</option>
                        )}
                        {invoiceStatusFilter && (
                          <option value="open">Open</option>
                        )}
                        {invoiceStatusFilter && (
                          <option value="paid">Paid</option>
                        )}
                        {invoiceStatusFilter && (
                          <option value="void">Void</option>
                        )}
                        {invoiceStatusFilter && (
                          <option value="uncollectable">Uncollectable</option>
                        )}

                        <option value="">All</option>
                      </select>
                    ) : tableOptions &&
                      column?.canFilter &&
                      column?.id == "createdAt" ? (
                      <DatePicker
                        placeholderText="Select Date"
                        clearButtonTitle={"Clear"}
                        isClearable={true}
                        maxDate={new Date()}
                        selected={selectedDate}
                        customInput={<Input></Input>}
                        showIcon={true}
                        title="Select Date"
                        onChange={date => {
                          setSelectedDate(date)
                          if (!date) {
                            setTableOptions({
                              ...tableOptions,
                              [column?.id]: null,
                            })
                            setUpdatePage(!updatePage)
                            return
                          }
                          const createdAtString = readableDate(date)
                          const createdAtDate = new Date(
                            createdAtString + " 00:00:00"
                          )
                          isValidDate(createdAtDate) &&
                            (setTableOptions({
                              ...tableOptions,
                              [column?.id]: createdAtString,
                            }) ||
                              setUpdatePage(!updatePage))
                        }}
                      />
                    ) : (
                      tableOptions &&
                      column?.canFilter && (
                        <Input
                          // value={filterValue || ''}
                          onChange={useAsyncDebounce(e => {
                            let columnId = column?.id
                            if (columnId?.split(".")?.length > 1)
                              columnId =
                                columnId?.split(".")[
                                  columnId?.split(".")?.length - 1
                                ]

                            setTableOptions({
                              ...tableOptions,
                              [columnId]: e.target.value,
                            })
                            setUpdatePage(!updatePage)
                          }, 500)}
                          placeholder={`search...`}
                        />
                      )
                    )}

                    {!tableOptions &&
                      (!tableOptions &&
                      column?.canFilter &&
                      (column?.id == "image" ||
                        column?.id == "status" ||
                        column?.id == "type") ? (
                        <SelectColumnFilter column={column} />
                      ) : (
                        <Filter column={column} />
                      ))}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map(cell => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </Table>
        {isLoading && tableOptions && (
          <div style={{ width: "10%", margin: "auto" }}>
            <ReactLoading
              type={"cylon"}
              color={"#ffffff"}
              height={"20%"}
              width={"20%"}
              style={{ margin: "auto" }}
            />
          </div>
        )}
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => {
                tableOptions
                  ? (() => {
                      setTableOptions({ ...tableOptions, page: 1 })
                      dispatch(setTableStates({ ...tableOptions, page: 1 }))

                      setUpdatePage(!updatePage)
                    })()
                  : gotoPage(0)
              }}
              disabled={
                tableOptions ? !tableOptions?.hasPreviousPage : !canPreviousPage
              }
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                tableOptions
                  ? (() => {
                      setTableOptions({
                        ...tableOptions,
                        page: tableOptions?.page - 1,
                      })
                      dispatch(
                        setTableStates({
                          ...tableOptions,
                          page: tableOptions?.page - 1,
                        })
                      )

                      setUpdatePage(!updatePage)
                    })()
                  : previousPage()
              }}
              disabled={
                tableOptions ? !tableOptions?.hasPreviousPage : !canPreviousPage
              }
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {tableOptions
              ? `${tableOptions?.page} of ${Number(tableOptions?.pageCount)}`
              : `${pageIndex + 1} of ${pageOptions.length}`}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={
              tableOptions
                ? Number(tableOptions?.pageCount)
                : pageOptions.length
            }
            defaultValue={tableOptions ? tableOptions?.page : pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              onClick={() => {
                tableOptions
                  ? (() => {
                      setTableOptions({
                        ...tableOptions,
                        page: Number(tableOptions.page) + 1,
                      })
                      dispatch(
                        setTableStates({
                          ...tableOptions,
                          page: Number(tableOptions.page) + 1,
                        })
                      )

                      setUpdatePage(!updatePage)
                    })()
                  : nextPage()
              }}
              disabled={
                tableOptions ? !tableOptions?.hasNextPage : !canNextPage
              }
            >
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                tableOptions
                  ? (() => {
                      setTableOptions({
                        ...tableOptions,
                        page: tableOptions?.pageCount,
                      })
                      dispatch(
                        setTableStates({
                          ...tableOptions,
                          page: tableOptions?.pageCount,
                        })
                      )

                      setUpdatePage(!updatePage)
                    })()
                  : gotoPage(pageCount - 1)
              }}
              disabled={
                tableOptions ? !tableOptions?.hasNextPage : !canNextPage
              }
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
