const defaultTableOptions = {
    page: 1,
    itemCount: 0,
    take: 10,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
    order: 'DESC',
    search: '',
}
export default defaultTableOptions;