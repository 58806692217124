import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import "react-date-range/dist/styles.css" // main style file
import "react-date-range/dist/theme/default.css" // theme css file

import { DateRangePicker } from "react-date-range"

export default function DateRangePickerComponent(props) {
  const date = new Date()
  const [state, setState] = useState([
    {
      startDate: new Date(
        localStorage.getItem("startDate") || `2024-${date.getMonth()}-01`
      ),
      endDate: new Date(
        localStorage.getItem("endDate") || `2024-${date.getMonth()}-30`
      ),
      key: "selection",
    },
  ])
  const { filters, setFilters, isDateSelected, setIsDateSelected } = props
  // useEffect(() => {
  //   setState([
  //     {

  //     }
  //   ])
  // }, []);

  // const selectionRange = {
  //   startDate: new Date(startDate),
  //   endDate: new Date(endDate),
  //   key: 'selection',
  // }
  const onDateChange = item => {
    const { startDate: start, endDate: end } = item.selection
    const startDate = `${start.getFullYear()}-${
      start.getMonth() + 1
    }-${start.getDate()}`
    const endDate = `${end.getFullYear()}-${
      end.getMonth() + 1
    }-${end.getDate()}`
    localStorage.setItem("startDate", startDate)
    localStorage.setItem("endDate", endDate)
    // console.log('control received', startDate, endDate);
    setIsDateSelected(true)
    setState([item.selection])
    setFilters({
      ...filters,
      start: localStorage.getItem("start"),
      end: localStorage.getItem("end"),
    })
  }
  return (
    <DateRangePicker
      id={9999}
      editableDateInputs={true}
      onChange={onDateChange}
      moveRangeOnFirstSelection={false}
      ranges={state}
    />
  )
}
