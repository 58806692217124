import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
  CardTitle,
  Spinner,
  Badge,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import Bookings from "./Leads"

import DeleteModal from "../../components/Common/DeleteModal"
import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "store/e-commerce/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../components/Common/TableContainer"

// Column
import {
  Id,
  Description,
  StripeInvoiceId,
  Amount,
  StripePaymentAttempt,
  SubscriptionId,
  JoiningDate,
  Status,
  User,
} from "./InvoiceCol"
import defaultTableOptions from "constants/tableOptions"

import AxiosInstance, { logAxiosError } from "services/axios_service"
import { readableDate } from "methods/globalMethods"
import GoogleMap from "./Map.js"

const LeadView = props => {
  //meta title
  document.title = "Customer | Measure Admin & Dashboard"

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { customers } = useSelector(state => ({
    customers: state.ecommerce.customers,
  }))

  const [tableOptions, setTableOptions] = useState(defaultTableOptions)
  const [tableData, setTableData] = useState([])
  const [isTableLoading, setTableIsLoading] = useState(false)
  const [updatePage, setUpdatePage] = useState(true)
  const [blockCounter, setBlockCounter] = useState(1)
  const [isEdit, setIsEdit] = useState(false)
  const [modal, setModal] = useState(false)
  const [saveUserBtnLoading, setSaveUserBtnLoading] = useState(false)
  const [isBlockBtnLoading, setIsBlockBtnLoading] = useState(false)
  const [imageModelOpen, setImageModelOpen] = useState(false)
  const [modelImage, setModalImage] = useState(null)

  const [userFields, setUserFields] = useState(null)
  const [leadPhotos, setLeadPhotos] = useState([])
  const [userInvoices, setUserInvoices] = useState([])
  const [filter, setFilter] = useState({
    roles: "",
    isBanned: "",
  })

  const [selectedItemId, setSelectedItemId] = useState(null)

  const [userModalFields, setUserModalFields] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    password1: "",
    password2: "",
    role: "",
  })

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

 

  const [errors, setErrors] = useState([])
  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)
  const itemId =
    location?.pathname?.split("/")[location?.pathname?.split("/").length - 1]
  // Table Column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        Cell: cellProps => {
          return <Id {...cellProps} />
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        filterable: true,
        Cell: cellProps => {
          return <Amount {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
      // {
      //   Header: 'Stripe Invoice ID',
      //   accessor: 'stripeInvoiceId',
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <StripeInvoiceId {...cellProps} />;
      //     ;
      //   }
      // },
      // {
      //   Header: 'Stripe Payment Attempt',
      //   accessor: 'stripePaymentAttempt',
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <StripePaymentAttempt {...cellProps} />;
      //     ;
      //   }
      // },
      // {
      //   Header: 'User',
      //   accessor: 'user',
      //   filterable: true,
      //   Cell: (cellProps) => {
      //     return <User {...cellProps} />;
      //     ;
      //   }
      // },
      {
        Header: "Date",
        accessor: "createdAt",
        filterable: true,
        Cell: cellProps => {
          return <JoiningDate {...cellProps} />
        },
      },
    ],
    []
  )
  const deleteItem = () => {
    AxiosInstance.delete(
      `${process.env.REACT_APP_BASE_URL}/api/users/${itemId}`
      // {
      //   data: { password: "password" },
      // }
    )
      .then(response => {
        setDeleteModal(false)
        navigate(`/customers`)
      })
      .catch(error => {
        logAxiosError(error)
        alert(error.response?.data?.message)
      })
  }

  let photosArray = []
  useEffect(() => {
    setTableIsLoading(true)
    setTableData([])

    AxiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/leads/${itemId}`)
      .then(response => {
        setUserFields(response?.data.data)
        setTableIsLoading(false)

        
      })
      .catch(error => {
        logAxiosError(error)
      })

      AxiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/measurements/measurementsByLead/${itemId}`)
      .then(response => {
        response?.data?.data.length > 0 && response?.data?.data.map(item =>{
          photosArray.push(item);
          setLeadPhotos(photosArray)
        });
      })        
      .catch(error => {
        logAxiosError(error)
      })

  }, [updatePage, blockCounter])

  function handleUserModalChange(evt) {
    const value = evt.target.value
    setUserModalFields({
      ...userModalFields,
      [evt.target.name]: value,
    })
  }

  function getUserDetails(id) {
    AxiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/api/admin/users/list-single-user/${id}`,
      { params: {} }
    )
      .then(response => {
        // setQuestionFields({ ...response.data, description: JSON.parse(response.data?.description) })
        setUserModalFields(response.data)
        setIsEdit(true)
        toggle()
      })
      .catch(error => {
        logAxiosError(error)
      })
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setUserModalFields({
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        password1: "",
        password2: "",
        role: "user",
      })
      setErrors([])
      // setselectedFiles([])
    } else {
      setModal(true)
    }
  }

  const saveUser = async () => {
    setSaveUserBtnLoading(true)
    let cleaObj = userModalFields
    // cleaObj = removeEmpty(cleaObj)
    // cleaObj.description = JSON.stringify(cleaObj.description)
    // const { link, type } = await uploadQuestionImage()
    // setSaveBtnLoading(true)
    AxiosInstance.patch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/users/update/${userModalFields?.id}`,
      cleaObj
      //  { ...cleaObj, description, ...((!selectedFiles[0] && !cleaObj.image) ? { image: " " } : { image: link }) }
    )
      .then(response => {
        // setSaveBtnLoading(false)
        toggle()
        setUpdatePage(!updatePage)
        setSaveUserBtnLoading(false)
        setErrors([])
      })
      .catch(error => {
        logAxiosError(error)
        setErrors(error.response.data.message)
        setSaveUserBtnLoading(false)
      })
  }

  const blockUnblockToggle = async object => {
    setIsBlockBtnLoading(true)
    AxiosInstance.patch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/users/blockuser/${object?.id}`,
      object
      //  { ...cleaObj, description, ...((!selectedFiles[0] && !cleaObj.image) ? { image: " " } : { image: link }) }
    )
      .then(response => {
        setIsBlockBtnLoading(false)
        setBlockCounter(blockCounter + Math.random())
      })
      .catch(error => {
        logAxiosError(error)
        alert(error.response?.data?.message)
      })
  }

  function removeEmpty(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null && v !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
  }

  const tdStyle = {
    textAlign: "left",
    width: "50%",
    fontSize: "0.8rem",
    fontWeight: "500",
  }
  const toggleImageModel = () => {
    setImageModelOpen(!imageModelOpen)
  }
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={deleteItem}
        onCloseClick={() => setDeleteModal(false)}
        itemName="User"
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Measure"
            breadcrumbItem={"Lead"}
            itemLink={userFields?.role == "user" ? "/customers" : "/leads"}
          />
          <Row>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div></div>
              <div style={{ float: "inline-end" }}>
                <div className="d-flex flex-wrap gap-2 mb-2">
                  <Button
                    color={"light"}
                    className="btn-rounded "
                    onClick={() => {
                      setUserModalFields(userFields)
                      setIsEdit(true)
                      toggle()
                    }}
                  >
                    Edit
                  </Button>

                  <Button
                    color={"danger"}
                    className="btn-rounded "
                    onClick={() => {
                      setDeleteModal(true)
                    }}
                  >
                    Delete
                  </Button>

                  <Button
                    color={
                      userFields?.status != "blocked" ? "danger" : "success"
                    }
                    className="btn-rounded "
                    onClick={() => {
                      blockUnblockToggle({
                        id: itemId,
                        status: userFields?.status != "blocked" ? true : false,
                      })
                    }}
                  >
                    {isBlockBtnLoading ? (
                      <>
                        <i
                          className="fa fa-spinner fa-pulse"
                          style={{ marginRight: "5px" }}
                        ></i>
                        {userFields?.isBanned ? "Unblocked" : "Blocked"}
                      </>
                    ) : userFields?.status == "blocked" ? (
                      "Approve"
                    ) : (
                      "Block"
                    )}
                  </Button>
                </div>
              </div>
            </div>
            <Col className="col-6">
              <Card>
                <CardBody>
                  <CardTitle className="h5 mb-3">User Details</CardTitle>

                  {userFields ? (
                    <div>
                      <Col md={12}>
                        <div
                          className="mt-4 mt-md-0"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          <img
                            className="rounded-circle"
                            alt="profile image"
                            width="130"
                            height="130"
                            src={
                              userFields?.image ||
                              "https://static.vecteezy.com/system/resources/previews/035/624/082/non_2x/user-profile-person-icon-in-flat-isolated-in-suitable-for-social-media-man-profiles-screensavers-depicting-male-face-silhouettes-for-apps-website-vector.jpg"
                            }
                            data-holder-rendered="true"
                          />
                        </div>
                      </Col>

                      <div className="table-responsive react-table">
                        <table
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                            // border: "1px solid #ddd",
                          }}
                        >
                          <tr className="border-bottom-td">
                            <td style={tdStyle}>Name</td>
                            <td
                              style={tdStyle}
                            >{`${userFields?.user?.firstName} ${userFields?.user?.lastName}`}</td>
                          </tr>

                          {/* <tr className="border-bottom-td">
                            <td style={tdStyle}>First Name</td>
                            <td style={tdStyle}>{userFields?.firstName}</td>
                          </tr>

                          <tr className="border-bottom-td">
                            <td style={tdStyle}>Last Name</td>
                            <td style={tdStyle}>{userFields?.lastName}</td>
                          </tr> */}

                          <tr className="border-bottom-td">
                            <td style={tdStyle}>Role</td>
                            <td style={tdStyle}>{userFields?.user?.role}</td>
                          </tr>

                          <tr className="border-bottom-td">
                            <td style={tdStyle}>Email</td>
                            <td style={tdStyle}>{userFields?.user?.email}</td>
                          </tr>

                          <tr className="border-bottom-td">
                            <td style={tdStyle}>Platform</td>
                            <td style={tdStyle}>{userFields?.user?.platform}</td>
                          </tr>

                          <tr className="border-bottom-td">
                            <td style={tdStyle}>Region</td>
                            <td style={tdStyle}>{userFields?.user?.region}</td>
                          </tr>

                          <tr className="border-bottom-td">
                            <td style={tdStyle}>Joined Platform</td>
                            <td style={tdStyle}>
                              {readableDate(userFields?.createdAt)}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <Col md={12}>
                      <div
                        className="mt-4 mt-md-0"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <Spinner className="ms-2" color="info" />
                      </div>
                    </Col>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col className="col-6">
              <Card>
                <CardBody>
                  <CardTitle className="h5 mb-3">Lead Details</CardTitle>

                  {userFields ? (
                    <div>
                      <div className="table-responsive react-table">
                        <table
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                            // border: "1px solid #ddd",
                          }}
                        >

                          <tr className="border-bottom-td">
                            <td style={tdStyle}>Project Name</td>
                            <td style={tdStyle}>{userFields?.projectName}</td>
                          </tr>

                          <tr className="border-bottom-td">
                            <td style={tdStyle}>Number Of Windows</td>
                            <td style={tdStyle}>{userFields?.numberOfWindows}</td>
                          </tr>

                          <tr className="border-bottom-td">
                            <td style={tdStyle}>Lead Creation Date</td>
                            <td style={tdStyle}>{readableDate(userFields?.createdAt)}</td>
                          </tr>

                          
                        </table>
                      </div>
                    </div>
                  ) : (
                    <Col md={12}>
                      <div
                        className="mt-4 mt-md-0"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <Spinner className="ms-2" color="info" />
                      </div>
                    </Col>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col>
            <Card>
                <CardBody>
                  <CardTitle className="h5 mb-3">
                    {leadPhotos.length > 0 ? `Photos`
                      : `No photos provided.`}
                  </CardTitle>
                  <div>
                    {leadPhotos && 
                      leadPhotos?.map((photo, index) => {
                        console.log("url is", index, photo?.pic?.url)
                        return (
                          <img
                            key={index}
                            src={photo?.pic?.url}
                            onClick={() => {
                              setDimensions({width: photo?.width, height: photo?.height})
                              setImageModelOpen(true)
                              setModalImage(photo?.pic?.url)
                            }}
                            width="150px"
                            // height="185px"
                            className="border-td object-fit-contain mx-auto px-1 py-1 rounded-xl shadow"
                          />
                        )
                      })}
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* <Col className="col-6" xs="6">
            
              <Card>
                <CardBody>
                  <CardTitle className="h5 mb-3">Invoices</CardTitle>
                  <TableContainer
                    columns={columns}
                    data={tableData}
                    isGlobalFilter={true}
                    isAddInvoiceList={false}
                    handleInvoiceClick={() => {}}
                    customPageSize={tableOptions?.take}
                    className="custom-header-css"
                    isLoading={isTableLoading}
                    setFilter={setFilter}
                    filter={filter}
                  />
                </CardBody>
              </Card>
            </Col> */}
          </Row>
          <Row>
            <Bookings userId={itemId} />
          </Row>
          <Modal
            size="lg"
            isOpen={modal}
            toggle={toggle}
            style={{ width: "75%" }}
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit User" : "Add User"}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <Row className="mb-3">
                    <Col className="col-6">
                      <div className="">
                        <Label className="form-label">First Name</Label>
                        <Input
                          name="firstName"
                          type="text"
                          placeholder="Enter first name"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.firstName}
                        />
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="">
                        <Label className="form-label">Last Name</Label>
                        <Input
                          name="lastName"
                          type="text"
                          placeholder="Enter last name"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.lastName}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-6">
                      <div className="">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          type="text"
                          placeholder="Enter email"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.email}
                        />
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="">
                        <Label className="form-label">Company Name</Label>
                        <Input
                          name="companyName"
                          type="text"
                          placeholder="Enter company name"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.companyName}
                        />
                      </div>
                    </Col>
                  </Row>

                  {!isEdit && (
                    <Row className="mb-3">
                      <Col className="col-6">
                        <div className="">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password1"
                            type="text"
                            placeholder="Enter password"
                            onChange={handleUserModalChange}
                            // onBlur={validation.handleBlur}
                            value={userModalFields?.password1}
                          />
                        </div>
                      </Col>
                      <Col className="col-6">
                        <div className="">
                          <Label className="form-label">Confirm Password</Label>
                          <Input
                            name="password2"
                            type="text"
                            placeholder="Re-enter password"
                            onChange={handleUserModalChange}
                            // onBlur={validation.handleBlur}
                            value={userModalFields?.password2}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}

                  <div className="form-floating mb-3">
                    {/* {fields?.status == "active" ? "0" : fields?.status == "draft" ? "1" : "2"} */}
                    <select
                      value={userModalFields?.role}
                      className="form-select"
                      name="role"
                      onChange={handleUserModalChange}
                    >
                      <option value="user">User</option>
                      <option value="admin">Admin</option>
                    </select>
                    <label htmlFor="floatingSelectGrid">Role</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      className="btn btn-success save-customer"
                      onClick={isEdit && saveUser}
                    >
                      {saveUserBtnLoading ? (
                        <i className="fa fa-spinner fa-pulse"></i>
                      ) : (
                        (isEdit ? "Save" : "Create") + " User"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
              {errors[0] && (
                <Row className="mt-3">
                  <Col>
                    <Alert color="danger" role="alert">
                      {errors[0] && errors[0]}
                    </Alert>
                  </Col>
                </Row>
              )}
            </ModalBody>
          </Modal>
          <Modal size="md" isOpen={imageModelOpen} style={{}}>
            <ModalBody>
              <Row className="mt-3">
                <Col>
                  <img
                    src={modelImage}
                    width="100%"
                    height="670px"
                    className="object-fit-contain"
                    style={{borderRadius: "5px"}}
                  />
                    <p className="text-center my-3">Width: {dimensions?.width } <br/> Height: {dimensions?.height}</p>
                  <div className="text-end my-3">
                    <button
                      className="btn btn-success save-customer"
                      onClick={toggleImageModel}
                    >
                      Close
                    </button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

// Users.propTypes = {
//   customers: PropTypes.array,
//   onGetCustomers: PropTypes.func,
//   onAddNewCustomer: PropTypes.func,
//   onDeleteCustomer: PropTypes.func,
//   onUpdateCustomer: PropTypes.func,
// };

export default LeadView
