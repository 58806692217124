import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "../../components/Common/DeleteModal"
import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "store/e-commerce/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../components/Common/TableContainer"

// Column
import {
  Id,
  User,
  ProjectName,
  Type,
  NumberOfWindows,
  CreationDate,
} from "./LeadCol"
import defaultTableOptions from "constants/tableOptions"

import AxiosInstance, { logAxiosError } from "services/axios_service"
import { readableDate } from "methods/globalMethods"

const Leads = props => {
  //meta title
  document.title = "Leads | Measure - Admin & Dashboard"
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { customers } = useSelector(state => ({
    customers: state.ecommerce.customers,
  }))

  const [tableOptions, setTableOptions] = useState(defaultTableOptions)
  const [tableData, setTableData] = useState([])
  const [isTableLoading, setTableIsLoading] = useState(false)
  const [updatePage, setUpdatePage] = useState(true)
  const [blockCounter, setBlockCounter] = useState(1)
  const [isEdit, setIsEdit] = useState(false)
  const [modal, setModal] = useState(false)
  const [saveUserBtnLoading, setSaveUserBtnLoading] = useState(false)
  const [filter, setFilter] = useState({})

  const [selectedItemId, setSelectedItemId] = useState(null)

  const [userModalFields, setUserModalFields] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    password: "",
    role: "",
    deviceToken: "",
    platform: "",
    region: "",
  })

  const [errors, setErrors] = useState([])

  // Module Column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        Cell: cellProps => {
          return <Id {...cellProps} />
        },
      },
      {
        Header: "User",
        accessor: "user",
        filterable: true,
        Cell: cellProps => {
          return <User {...cellProps} />
        },
      },
      {
        Header: "Project Name",
        accessor: "projectName",
        filterable: true,
        Cell: cellProps => {
          return <ProjectName {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "type",
        filterable: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Number of Windows",
        accessor: "numberOfWindows",
        filterable: true,
        Cell: cellProps => {
          return <NumberOfWindows {...cellProps} />
        },
      },
      {
        Header: "Creation Date",
        accessor: "createdAt",
        filterable: false,
        Cell: cellProps => {
          return <CreationDate {...cellProps} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => {
                    const customerData = cellProps.row.original
                    navigate(`/leads/${customerData?.id}`, {
                      state: { parent: "/leads" },
                    })
                  }}
                >
                  <i
                    className="mdi mdi-eye font-size-16 me-1"
                    id="viewtooltip"
                  ></i>
                  View
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    getUserDetails(cellProps.row.original?.id)
                    setModal(true)
                  }}
                >
                  <i
                    className="mdi mdi-pencil font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>
                  Edit
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    setSelectedItemId(cellProps.row.original?.id)
                    setDeleteModal(true)
                  }}
                >
                  <i
                    className="mdi mdi-trash-can font-size-16 text-danger me-1"
                    id="deletetooltip"
                  ></i>
                  Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    []
  )

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const deleteItem = () => {
    AxiosInstance.delete(
      `${process.env.REACT_APP_BASE_URL}/api/users/${selectedItemId}`,
      {
        data: { password: "password" },
      }
    )
      .then(response => {
        setUpdatePage(!updatePage)
        setDeleteModal(false)
      })
      .catch(error => {
        logAxiosError(error)
        alert(error.response?.data?.message)
      })
  }

  useEffect(() => {
    setTableIsLoading(true)
    setTableData([])

    AxiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/leads`, {
      params: { ...tableOptions, ...filter },
    })
      .then(response => {
        console.log("🚀 ~ payload ::>", tableOptions)
        setTableOptions({
          ...tableOptions,
          ...response.data.meta,
        })
        console.log("🚀 ~ tableOptions ::>", tableOptions)
        response.data?.data?.map(item => {
          item.createdAt = readableDate(item.createdAt)
          item.user = item.user
          // item.status = item.status ? item.status : "Pending"
          // item.confirmed = item.otpVerified ? "Verified" : "Unverified"
        })
        setTableData(response?.data?.data)
        setTableIsLoading(false)
      })
      .catch(error => {
        logAxiosError(error)
      })
  }, [updatePage, blockCounter, filter])

  function handleUserModalChange(evt) {
    const value = evt.target.value
    setUserModalFields({
      ...userModalFields,
      [evt.target.name]: value,
    })
  }
  function getUserDetails(id) {
    AxiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/users/${id}`)
      .then(response => {
        // setQuestionFields({ ...response.data, description: JSON.parse(response.data?.description) })
        setUserModalFields(response.data.data)
        setIsEdit(true)
        toggle()
      })
      .catch(error => {
        logAxiosError(error)
      })
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setUserModalFields({
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        password: "",
        role: "",
        deviceToken: "",
        platform: "",
        region: "",
      })
      setErrors([])
      // setselectedFiles([])
    } else {
      setModal(true)
    }
  }

  const creatUser = async () => {
    setSaveUserBtnLoading(true)
    let cleaObj = userModalFields
    AxiosInstance.post(`${process.env.REACT_APP_BASE_URL}/api/auth/signup`, {
      ...cleaObj,
      deviceToken: null,
    })
      .then(response => {
        // setSaveBtnLoading(false)
        toggle()
        setUpdatePage(!updatePage)
        setSaveUserBtnLoading(false)
        setErrors([])
      })
      .catch(error => {
        logAxiosError(error)
        setErrors(error.response.data.message)
        setSaveUserBtnLoading(false)
      })
  }

  const saveUser = async () => {
    setSaveUserBtnLoading(true)
    let cleaObj = userModalFields
    // cleaObj = removeEmpty(cleaObj)
    // cleaObj.description = JSON.stringify(cleaObj.description)
    // const { link, type } = await uploadQuestionImage()
    // setSaveBtnLoading(true)
    AxiosInstance.put(
      `${process.env.REACT_APP_BASE_URL}/api/users/${cleaObj?.id}`,
      cleaObj
      //  { ...cleaObj, description, ...((!selectedFiles[0] && !cleaObj.image) ? { image: " " } : { image: link }) }
    )
      .then(response => {
        // setSaveBtnLoading(false)
        toggle()
        setUpdatePage(!updatePage)
        setSaveUserBtnLoading(false)
        setErrors([])
      })
      .catch(error => {
        logAxiosError(error)
        setErrors(error.response.data.message)
        setSaveUserBtnLoading(false)
      })
  }

  const blockUnblockToggle = async object => {
    AxiosInstance.patch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/users/blockuser/${object?.id}`,
      object
      //  { ...cleaObj, description, ...((!selectedFiles[0] && !cleaObj.image) ? { image: " " } : { image: link }) }
    )
      .then(response => {
        setBlockCounter(blockCounter + Math.random())
      })
      .catch(error => {
        logAxiosError(error)
        alert(error.response?.data?.message)
      })
  }

  const confimUnConfirmToggle = async object => {
    AxiosInstance.patch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/users/update/${object?.id}`,
      object
      //  { ...cleaObj, description, ...((!selectedFiles[0] && !cleaObj.image) ? { image: " " } : { image: link }) }
    )
      .then(response => {
        setBlockCounter(blockCounter + Math.random())
      })
      .catch(error => {
        logAxiosError(error)
        alert(error.response?.data?.message)
      })
  }
  function removeEmpty(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null && v !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={deleteItem}
        onCloseClick={() => setDeleteModal(false)}
        itemName="User"
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Leads" breadcrumbItem="Leads" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={tableData}
                    isGlobalFilter={true}
                    isAddUserListServer={true}
                    handleUserClickServer={() => {
                      setIsEdit(false)
                      toggle()
                      // navigate("/create-module", {/*params*/ })
                    }}
                    customPageSize={tableOptions?.take}
                    className="custom-header-css"
                    isLoading={isTableLoading}
                    tableOptions={tableOptions}
                    setTableOptions={setTableOptions}
                    updatePage={updatePage}
                    setUpdatePage={setUpdatePage}
                    userStatusFilter={true}
                    setFilter={setFilter}
                    filter={filter}
                    userType={"user"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            isOpen={modal}
            toggle={toggle}
            style={{ width: "75%" }}
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit User" : "Add User"}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <Row className="mb-3">
                    <Col className="col-6">
                      <div className="">
                        <Label className="form-label">First Name</Label>
                        <Input
                          name="firstName"
                          type="text"
                          placeholder="Enter first name"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.firstName}
                        />
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="">
                        <Label className="form-label">Last Name</Label>
                        <Input
                          name="lastName"
                          type="text"
                          placeholder="Enter last name"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.lastName}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-12">
                      <div className="">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          type="text"
                          placeholder="Enter email"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.email}
                        />
                      </div>
                    </Col>
                    {/* <Col className="col-6">
                      <div className="">
                        <Label className="form-label">Company Name</Label>
                        <Input
                          name="companyName"
                          type="text"
                          placeholder="Enter company name"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.companyName}
                        />
                      </div>
                    </Col> */}
                  </Row>

                  {!isEdit && (
                    <Row className="mb-3">
                      <Col className="col-6">
                        <div className="">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            type="text"
                            placeholder="Enter password"
                            onChange={handleUserModalChange}
                            // onBlur={validation.handleBlur}
                            value={userModalFields?.password}
                          />
                        </div>
                      </Col>

                      <Col className="col-6">
                        <div className="">
                          <Label className="form-label">Region</Label>
                          <Input
                            name="region"
                            type="text"
                            placeholder="Region"
                            onChange={handleUserModalChange}
                            // onBlur={validation.handleBlur}
                            required
                            value={userModalFields?.region}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}

                  <div className="form-floating mb-3">
                    {/* {fields?.status == "active" ? "0" : fields?.status == "draft" ? "1" : "2"} */}
                    <select
                      value={userModalFields?.role}
                      className="form-select"
                      name="role"
                      required
                      disabled={false}
                      onChange={handleUserModalChange}
                    >
                      <option value="user">User</option>
                      <option value="admin">Admin</option>
                    </select>
                    <label htmlFor="floatingSelectGrid">Role</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      className="btn btn-success save-customer"
                      onClick={isEdit ? saveUser : creatUser}
                    >
                      {saveUserBtnLoading ? (
                        <i className="fa fa-spinner fa-pulse"></i>
                      ) : (
                        (isEdit ? "Save" : "Create") + " User"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
              {errors[0] && (
                <Row className="mt-3">
                  <Col>
                    <Alert color="danger" role="alert">
                      {errors && errors}
                    </Alert>
                  </Col>
                </Row>
              )}
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

// Users.propTypes = {
//   customers: PropTypes.array,
//   onGetCustomers: PropTypes.func,
//   onAddNewCustomer: PropTypes.func,
//   onDeleteCustomer: PropTypes.func,
//   onUpdateCustomer: PropTypes.func,
// };

export default Leads
