import { readableDate } from 'methods/globalMethods';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

const Id = (cell) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};

const Description = (cell) => {
    return cell.value ? cell.value : '';
};

const StripeInvoiceId = (cell) => {
    return cell.value ? cell.value : '';
};

const Amount = (cell) => {
    return cell.value ? cell.value : '';
};

const StripePaymentAttempt = (cell) => {
    return cell.value ? cell.value : 0;
};

const SubscriptionId = (cell) => {
    return cell.value ? cell.value : '';
};

const Status = (cell) => {
    return <Badge
        className={"font-size-12 badge-soft-" +
            ((cell.value == 'paid') ? "success" : "danger")}
    >
        {cell.value}
    </Badge>
};

const User = (cell) => {
    return cell.value ? cell.value : '';
};

// const Address = (cell) => {
//     return cell.value ? cell.value : '';
// };

// const Rating = (cell) => {
//     return (
//         <span className="bg-success font-size-12 badge bg-success"><i className="mdi mdi-star me-1"></i>{cell.value}</span>
//     )
// };

// const WalletBalances = (cell) => {
//     return cell.value ? cell.value : '';
// };

const JoiningDate = (cell) => {
    return cell.value ? cell.value : '';
};

export {
    Id,
    Description,
    StripeInvoiceId,
    Amount,
    StripePaymentAttempt,
    SubscriptionId,
    JoiningDate,
    Status,
    User,
};