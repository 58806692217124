import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor"

const chartapex = ({ dataColors, chartData }) => {
  const { data, daysArray, title, xAxisTitle, yAxisTitle } = chartData
  // console.log('data, daysArray, title, xAxisTitle, yAxisTitle', data, daysArray, title, xAxisTitle, yAxisTitle);

  const apaexlineChartColors = getChartColorsArray(dataColors)

  const series = data
    ? data
    : [
        { name: "High - 2018", data: [26, 24, 32, 36, 33, 31, 33] },
        { name: "Low - 2018", data: [14, 11, 16, 12, 17, 13, 12] },
      ]
  const options = {
    chart: {
      height: 380,
      type: "line",
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    colors: apaexlineChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3],
      curve: "straight",
    },
    series: data
      ? data
      : [
          {
            name: "High - 2018",
            data: [26, 24, 32, 36, 33, 31, 33],
          },
          {
            name: "Low - 2018",
            data: [14, 11, 16, 12, 17, 13, 12],
          },
        ],
    title: {
      text: title ? title : "Average High & Low Temperature",
      align: "left",
      style: {
        fontWeight: "500",
      },
    },
    grid: {
      row: {
        colors: ["transparent", "transparent"],
        opacity: 0.2,
      },
      borderColor: "#f1f1f1",
    },
    markers: {
      style: "inverted",
      size: 6,
    },
    xaxis: {
      type: "datetime",
      categories: daysArray
        ? daysArray
        : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      title: {
        text: xAxisTitle ? xAxisTitle : "Month",
      },
    },
    yaxis: {
      title: {
        text: yAxisTitle ? yAxisTitle : "Temperature",
      },
      // min: 5,
      // max: 40
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="380"
      className="apex-charts"
    />
  )
}

export default chartapex
