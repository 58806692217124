import React from "react"
import { Navigate } from "react-router-dom"

import Users from "../cp_pages/Users/index"
import Candidates from "../cp_pages/Users/candidates"
import CustomersView from "../cp_pages/Users/customer_view"
import SignIn from "../cp_pages/Auth/Login"
import Dashboard from "../cp_pages/Dashboard/index"
import Locations from "../cp_pages/Locations/index"
import Leads from "../cp_pages/Leads/index"
import LeadView from "cp_pages/Leads/lead_single"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/users", component: <Users /> },
  { path: "/customers", component: <Candidates /> },
  { path: "/customers/:itemId", component: <CustomersView /> },
  { path: "/locations", component: <Locations /> },
  { path: "/leads", component: <Leads /> },
  { path: "/leads/:itemId", component: <LeadView /> },
  // //profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/customers" />,
  },
  {
    path: "*",
    exact: true,
    component: <Navigate to="/customers" />,
  },
]

const publicRoutes = [
  { path: "/", exact: true, component: <Navigate to="/customers" /> },
  { path: "/sign-in", component: <SignIn /> },
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
]

export { authProtectedRoutes, publicRoutes }
