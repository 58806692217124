import { readableDate } from "methods/globalMethods"
import React from "react"
import { Link } from "react-router-dom"
import { Badge } from "reactstrap"

export const LocationIdField = cell => {
  return cell.value ? cell.value : ""
}
export const Day = cell => {
  return cell.value ? cell.value : ""
}

export const StartTime = cell => {
  return cell.value ? readableDate(cell.value) : ""
}

export const EndTime = cell => {
  return cell.value ? readableDate(cell.value) : ""
}
export const CreatedAt = cell => {
  return readableDate(cell.value)
}
