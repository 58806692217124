import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as Yup from "yup"
import { useFormik } from "formik"
import TimeStampToDate from "../../../components/Common/TimeStampToDate"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "../../../components/Common/DeleteModal"
import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "store/e-commerce/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../../components/Common/TableContainer"

// Column
import {
  Id,
  User,
  ProjectName,
  Type,
  NumberOfWindows,
  CreationDate,
} from "./LeadsCols"
import defaultTableOptions from "constants/tableOptions"

import AxiosInstance, { logAxiosError } from "services/axios_service"
import { readableDate } from "methods/globalMethods"
import TimeStampToHours from "../../../components/Common/TimeStampToHours"

const Bookings = props => {
  //meta title
  document.title = "Leads | Measure Admin & Dashboard"

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [tableOptions, setTableOptions] = useState(defaultTableOptions)
  const [tableData, setTableData] = useState([])
  const [isTableLoading, setTableIsLoading] = useState(false)
  const [updatePage, setUpdatePage] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [modal, setModal] = useState(false)
  const [bookingId, setBookingId] = useState(null)
  const [refundValue, setRefundValue] = useState(false)
  const [filter, setFilter] = useState({
    // status: "",
  })
  const [modalFields, setModalFields] = useState({})

  const [selectedItemId, setSelectedItemId] = useState(null)

  const [errors, setErrors] = useState([])

  const tdStyle = {
    textAlign: "left",
    width: "50%",
    fontSize: "0.8rem",
    fontWeight: "500",
  }
  // Module Column

  function timestampToFormattedDate(timestamp) {
    // Create a new Date object from the timestamp (in milliseconds)
    const date = new Date(timestamp)

    // Year, month, day components
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0") // Add leading zero for single-digit months
    const day = String(date.getDate()).padStart(2, "0") // Add leading zero for single-digit days
    const hours = String(date.getHours()).padStart(2, "0") // Add leading zero for single-digit hours
    const minutes = String(date.getMinutes()).padStart(2, "0") // Add leading zero for single-digit minutes
    // Return formatted date string
    return `${year}-${month}-${day} ${hours}:${minutes}`
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        Cell: cellProps => {
          return <Id {...cellProps} />
        },
      },
      {
        Header: "User",
        accessor: "user",
        filterable: true,
        Cell: cellProps => {
          return <User {...cellProps} />
        },
      },
      {
        Header: "Project Name",
        accessor: "projectName",
        filterable: true,
        Cell: cellProps => {
          return <ProjectName {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "type",
        filterable: true,
        Cell: cellProps => {
          return <Type {...cellProps} />
        },
      },
      {
        Header: "Number of Windows",
        accessor: "numberOfWindows",
        filterable: true,
        Cell: cellProps => {
          return <NumberOfWindows {...cellProps} />
        },
      },
      {
        Header: "Creation Date",
        accessor: "createdAt",
        filterable: false,
        Cell: cellProps => {
          return <CreationDate {...cellProps} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => {
                    const customerData = cellProps.row.original
                    navigate(`/customers/${customerData?.id}`, {
                      state: { parent: "/customers" },
                    })
                  }}
                >
                  <i
                    className="mdi mdi-eye font-size-16 me-1"
                    id="viewtooltip"
                  ></i>
                  View
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    getUserDetails(cellProps.row.original?.id)
                    setModal(true)
                  }}
                >
                  <i
                    className="mdi mdi-pencil font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>
                  Edit
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    setSelectedItemId(cellProps.row.original?.id)
                    setDeleteModal(true)
                  }}
                >
                  <i
                    className="mdi mdi-trash-can font-size-16 text-danger me-1"
                    id="deletetooltip"
                  ></i>
                  Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    []
  )

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const handleCancellation = () => {
    AxiosInstance.post(
      `${process.env.REACT_APP_BASE_URL}/api/admin/bookings/cancel-booking/${selectedItemId}`,
      {
        refund: refundValue,
      }
    )
      .then(response => {
        setUpdatePage(!updatePage)
        setDeleteModal(false)
      })
      .catch(error => {
        logAxiosError(error)
        alert(error.response?.data?.message)
      })
  }

  const fetchSingleBooking = id => {
    AxiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/user/${id}`)
      .then(response => {
        setModalFields(response?.data?.data)
        toggle()
      })
      .catch(error => {
        logAxiosError(error)
      })
  }

  useEffect(() => {
    setTableIsLoading(true)
    setTableData([])
    const roleFilter =
      props.role == "customer"
        ? { customerId: props.itemId }
        : { hostID: props.itemId }

    AxiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/api/leads/user/${props.userId}`,
      {
        params: { ...tableOptions, ...filter, ...roleFilter },
      }
    )
      .then(response => {
        setTableOptions({ ...tableOptions, ...response.data?.data?.meta })
        const processedBookings = response.data?.data?.map(item => ({
          ...item,
          createdAt: readableDate(item.createdAt),
        }))
        setTableData(processedBookings)

        setTableIsLoading(false)
      })
      .catch(error => {
        logAxiosError(error)
      })
  }, [updatePage, filter])

  // const getReviewDetail = id => {
  //   AxiosInstance.get(`/api/review/${id}`)
  //     .then(response => {
  //       setModalFields(response?.data)
  //       toggle()
  //     })
  //     .catch(error => {
  //       logAxiosError(error)
  //     })
  // }
  const toggle = () => {
    if (modal) {
      setModal(false)
      setModalFields({})
    } else {
      setModal(true)
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleCancellation}
        onCloseClick={() => setDeleteModal(false)}
        itemName="Booking"
        onCheckBoxChange={() => {
          setRefundValue(!refundValue)
        }}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Leads" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={tableData}
                    isGlobalFilter={true}
                    customPageSize={tableOptions?.take}
                    className="custom-header-css overflow-auto"
                    isLoading={isTableLoading}
                    tableOptions={tableOptions}
                    setTableOptions={setTableOptions}
                    updatePage={updatePage}
                    setUpdatePage={setUpdatePage}
                    setFilter={setFilter}
                    filter={filter}
                    isAssessmentListing={true}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="md"
            isOpen={modal}
            toggle={toggle}
            style={{ width: "75%" }}
          >
            <ModalHeader toggle={toggle} tag="h4">
              Booking Information
            </ModalHeader>
            <ModalBody className="p-5">
              <Row>
                <table
                  style={{
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                    width: "100%",
                    // border: "1px solid #ddd",
                  }}
                >
                  <tr className="border-bottom-td">
                    <td style={tdStyle}>ID</td>
                    <td style={tdStyle}>{modalFields?.id}</td>
                  </tr>

                  <tr className="border-bottom-td">
                    <td style={tdStyle}>Status</td>
                    <td style={tdStyle}>{modalFields?.status}</td>
                  </tr>
                  <tr className="border-bottom-td">
                    <td style={tdStyle}>Duration</td>
                    <td style={tdStyle}>
                      {modalFields?.chargingDuration / 3600 + " " + "h"}
                    </td>
                  </tr>

                  <tr className="border-bottom-td">
                    <td style={tdStyle}>Start Time</td>
                    <td style={tdStyle}>
                      {" "}
                      <TimeStampToDate timestamp={modalFields?.startTime} />
                    </td>
                  </tr>

                  <tr className="border-bottom-td">
                    <td style={tdStyle}>End Time</td>
                    <td style={tdStyle}>
                      {" "}
                      <TimeStampToDate timestamp={modalFields?.endTime} />
                    </td>
                  </tr>

                  <tr className="border-bottom-td">
                    <td style={tdStyle}>Payment Status</td>
                    <td style={tdStyle}>
                      {modalFields?.paymentStatus
                        ? modalFields?.paymentStatus
                        : ""}
                    </td>
                  </tr>

                  <tr className="border-bottom-td">
                    <td style={tdStyle}>Created On</td>
                    <td style={tdStyle}>
                      {readableDate(modalFields?.createdAt)}
                    </td>
                  </tr>
                </table>
              </Row>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Bookings
