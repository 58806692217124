import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

import DeleteModal from "../../components/Common/DeleteModal"
import {
  getCustomers as onGetCustomers,
  addNewCustomer as onAddNewCustomer,
  updateCustomer as onUpdateCustomer,
  deleteCustomer as onDeleteCustomer,
} from "store/e-commerce/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "../../components/Common/TableContainer"

// Column
import { LocationIdField, Day, StartTime, EndTime, CreatedAt } from "./cols.js"
import defaultTableOptions from "constants/tableOptions"

import AxiosInstance, { logAxiosError } from "services/axios_service"
import { readableDate } from "methods/globalMethods"

const Locations = props => {
  //meta title
  document.title = "Locations | Measure Admin & Dashboard"
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { customers } = useSelector(state => ({
    customers: state.ecommerce.customers,
  }))

  const [tableOptions, setTableOptions] = useState(defaultTableOptions)
  const [tableData, setTableData] = useState([])
  const [isTableLoading, setTableIsLoading] = useState(false)
  const [updatePage, setUpdatePage] = useState(true)
  const [blockCounter, setBlockCounter] = useState(1)
  const [isEdit, setIsEdit] = useState(false)
  const [modal, setModal] = useState(false)
  const [saveUserBtnLoading, setSaveUserBtnLoading] = useState(false)
  const [filter, setFilter] = useState({})

  const [selectedItemId, setSelectedItemId] = useState(null)

  const [userModalFields, setUserModalFields] = useState({
    id: "",
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    password1: "",
    password2: "",
    roles: "customer",
    status: "pending",
  })

  const [errors, setErrors] = useState([])

  // Module Column
  const columns = useMemo(
    () => [
      {
        Header: "Location ID",
        accessor: "locationId",
        filterable: true,
        Cell: cellProps => {
          return <LocationIdField {...cellProps} />
        },
      },
      {
        Header: "Day",
        accessor: "day",
        filterable: true,
        Cell: cellProps => {
          return <Day {...cellProps} />
        },
      },
      {
        Header: "Start Time",
        accessor: "startTime",
        filterable: true,
        Cell: cellProps => {
          return <StartTime {...cellProps} />
        },
      },
      {
        Header: "End Time",
        accessor: "endTime",
        filterable: true,
        Cell: cellProps => {
          return <EndTime {...cellProps} />
        },
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        filterable: true,
        Cell: cellProps => {
          return <CreatedAt {...cellProps} />
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                {/* <DropdownItem
                  onClick={() => {
                    const customerData = cellProps.row.original
                    navigate(`/users/${customerData?.id}`, {
                      state: { parent: "/candidates" },
                    })
                  }}
                >
                  <i
                    className="mdi mdi-eye font-size-16 me-1"
                    id="viewtooltip"
                  ></i>
                  View
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View
                  </UncontrolledTooltip>
                </DropdownItem> */}

                {/* <DropdownItem
                  onClick={() => {
                    getUserDetails(cellProps.row.original?.id)
                  }}
                >
                  <i
                    className="mdi mdi-pencil font-size-16 text-success me-1"
                    id="edittooltip"
                  ></i>
                  Edit
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </DropdownItem> */}

                <DropdownItem
                  onClick={() => {
                    setSelectedItemId(cellProps.row.original?.id)
                    setDeleteModal(true)
                  }}
                >
                  <i
                    className="mdi mdi-trash-can font-size-16 text-danger me-1"
                    id="deletetooltip"
                  ></i>
                  Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem>

                {/* <DropdownItem
                  onClick={() => {
                    console.log(
                      "%cindex.js line:228 { id: cellProps.row.original?.id, isBanned: cellProps.row.original?.isBanned ? false : true }",
                      "color: #007acc;",
                      {
                        id: cellProps.row.original?.id,
                        status:
                          cellProps.row.original?.status !== "blocked"
                            ? false
                            : true,
                      }
                    )
                    blockUnblockToggle({
                      id: cellProps.row.original?.id,
                      status:
                        cellProps.row.original?.status == "blocked"
                          ? false
                          : true,
                    })
                  }}
                >
                  {cellProps.row.original?.status == "blocked" ? (
                    <i
                      className="mdi mdi-lock-open font-size-16 text-danger me-1"
                      id="blocktoggle"
                    ></i>
                  ) : (
                    <i
                      className="mdi mdi-lock font-size-16 text-danger me-1"
                      id="blocktoggle"
                    ></i>
                  )}
                  {cellProps.row.original?.status == "blocked"
                    ? "Unblock"
                    : "Block"}
                  <UncontrolledTooltip placement="top" target="blocktoggle">
                    {cellProps.row.original?.status == "blocked"
                      ? "Unblock"
                      : "Block"}
                  </UncontrolledTooltip>
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        },
      },
    ],
    []
  )

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false)

  const deleteItem = () => {
    AxiosInstance.delete(
      `${process.env.REACT_APP_BASE_URL}/api/admin/users/delete/${selectedItemId}`,
      {
        data: { password: "password" },
      }
    )
      .then(response => {
        setUpdatePage(!updatePage)
        setDeleteModal(false)
      })
      .catch(error => {
        logAxiosError(error)
        alert(error.response?.data?.message)
      })
  }

  useEffect(() => {
    setTableIsLoading(true)
    setTableData([])

    AxiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/api/locations-availability/`,
      {
        params: { ...tableOptions, ...filter, role: "customer" },
      }
    )
      .then(response => {
        setTableOptions({
          ...tableOptions,
          ...{ itemCount: response.data?.length },
        })
        response?.data?.data?.map(item => {
          item.createdAt = item.createdAt
          item.startTime = item.startTime
          item.endTime = item.endTime
          item.day = item.day
          item.locationId = item.locationId
        })
        setTableData(response.data)
        setTableIsLoading(false)
      })
      .catch(error => {
        logAxiosError(error)
      })
  }, [updatePage, blockCounter, filter])

  function handleUserModalChange(evt) {
    const value = evt.target.value
    setUserModalFields({
      ...userModalFields,
      [evt.target.name]: value,
    })
  }
  function getUserDetails(id) {
    AxiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/api/admin/users/list-single-user/${id}`
    )
      .then(response => {
        // setQuestionFields({ ...response.data, description: JSON.parse(response.data?.description) })
        setUserModalFields(response.data.data)
        setIsEdit(true)
        toggle()
      })
      .catch(error => {
        logAxiosError(error)
      })
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setUserModalFields({
        firstName: "",
        lastName: "",
        companyName: "",
        email: "",
        password1: "",
        password2: "",
        roles: "",
      })
      setErrors([])
      // setselectedFiles([])
    } else {
      setModal(true)
    }
  }

  const creatUser = async () => {
    setSaveUserBtnLoading(true)
    let cleaObj = userModalFields
    // cleaObj = removeEmpty(cleaObj)
    // cleaObj.description = JSON.stringify(cleaObj.description)
    // const { link, type } = await uploadQuestionImage()
    // console.log('%cEditModule.js line:270 link, type', 'color: #007acc;', link, type);

    AxiosInstance.post(
      `${process.env.REACT_APP_BASE_URL}/api/admin/users/create`,
      {
        ...cleaObj,
      }
    )
      .then(response => {
        // setSaveBtnLoading(false)
        toggle()
        setUpdatePage(!updatePage)
        setSaveUserBtnLoading(false)
        setErrors([])
      })
      .catch(error => {
        logAxiosError(error)
        setErrors(error.response.data.message)
        setSaveUserBtnLoading(false)
      })
  }

  const saveUser = async () => {
    setSaveUserBtnLoading(true)
    let cleaObj = userModalFields
    // cleaObj = removeEmpty(cleaObj)
    // cleaObj.description = JSON.stringify(cleaObj.description)
    // const { link, type } = await uploadQuestionImage()
    // setSaveBtnLoading(true)
    AxiosInstance.patch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/users/update/${cleaObj?.id}`,
      cleaObj
      //  { ...cleaObj, description, ...((!selectedFiles[0] && !cleaObj.image) ? { image: " " } : { image: link }) }
    )
      .then(response => {
        // setSaveBtnLoading(false)
        toggle()
        setUpdatePage(!updatePage)
        setSaveUserBtnLoading(false)
        setErrors([])
      })
      .catch(error => {
        logAxiosError(error)
        setErrors(error.response.data.message)
        setSaveUserBtnLoading(false)
      })
  }

  const blockUnblockToggle = async object => {
    AxiosInstance.patch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/users/blockuser/${object?.id}`,
      object
      //  { ...cleaObj, description, ...((!selectedFiles[0] && !cleaObj.image) ? { image: " " } : { image: link }) }
    )
      .then(response => {
        setBlockCounter(blockCounter + Math.random())
      })
      .catch(error => {
        logAxiosError(error)
        alert(error.response?.data?.message)
      })
  }

  function removeEmpty(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null && v !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={deleteItem}
        onCloseClick={() => setDeleteModal(false)}
        itemName="User"
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Measure" breadcrumbItem="Locations" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={tableData}
                    isGlobalFilter={true}
                    isAddUserListServer={true}
                    handleUserClickServer={() => {
                      setIsEdit(false)
                      toggle()
                      // navigate("/create-module", {/*params*/ })
                    }}
                    customPageSize={tableOptions?.take}
                    className="custom-header-css"
                    isLoading={isTableLoading}
                    tableOptions={tableOptions}
                    setTableOptions={setTableOptions}
                    updatePage={updatePage}
                    setUpdatePage={setUpdatePage}
                    userStatusFilter={true}
                    setFilter={setFilter}
                    filter={filter}
                    userType={"user"}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            size="lg"
            isOpen={modal}
            toggle={toggle}
            style={{ width: "75%" }}
          >
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Location" : "Add Location"}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="col-12">
                  <Row className="mb-3">
                    <Col className="col-6">
                      <div className="">
                        <Label className="form-label"></Label>
                        <Input
                          name="firstName"
                          type="text"
                          placeholder="Enter first name"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.firstName}
                        />
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="">
                        <Label className="form-label">Last Name</Label>
                        <Input
                          name="lastName"
                          type="text"
                          placeholder="Enter last name"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.lastName}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col className="col-6">
                      <div className="">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          type="text"
                          placeholder="Enter email"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.email}
                        />
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="">
                        <Label className="form-label">Company Name</Label>
                        <Input
                          name="companyName"
                          type="text"
                          placeholder="Enter company name"
                          onChange={handleUserModalChange}
                          // onBlur={validation.handleBlur}
                          value={userModalFields?.companyName}
                        />
                      </div>
                    </Col>
                  </Row>

                  {!isEdit && (
                    <Row className="mb-3">
                      <Col className="col-6">
                        <div className="">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password1"
                            type="text"
                            placeholder="Enter password"
                            onChange={handleUserModalChange}
                            // onBlur={validation.handleBlur}
                            value={userModalFields?.password1}
                          />
                        </div>
                      </Col>
                      <Col className="col-6">
                        <div className="">
                          <Label className="form-label">Confirm Password</Label>
                          <Input
                            name="password2"
                            type="text"
                            placeholder="Re-enter password"
                            onChange={handleUserModalChange}
                            // onBlur={validation.handleBlur}
                            value={userModalFields?.password2}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}

                  <div className="form-floating mb-3">
                    {/* {fields?.status == "active" ? "0" : fields?.status == "draft" ? "1" : "2"} */}
                    <select
                      value={userModalFields?.roles}
                      className="form-select"
                      name="roles"
                      disabled={false}
                      onChange={handleUserModalChange}
                    >
                      <option value="customer">Customer</option>
                      <option value="host">Host</option>
                      <option value="admin">Admin</option>
                    </select>
                    <label htmlFor="floatingSelectGrid">Role</label>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      className="btn btn-success save-customer"
                      onClick={isEdit ? saveUser : creatUser}
                    >
                      {saveUserBtnLoading ? (
                        <i className="fa fa-spinner fa-pulse"></i>
                      ) : (
                        (isEdit ? "Save" : "Create") + " User"
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
              {errors[0] && (
                <Row className="mt-3">
                  <Col>
                    <Alert color="danger" role="alert">
                      {errors && errors}
                    </Alert>
                  </Col>
                </Row>
              )}
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

// Users.propTypes = {
//   customers: PropTypes.array,
//   onGetCustomers: PropTypes.func,
//   onAddNewCustomer: PropTypes.func,
//   onDeleteCustomer: PropTypes.func,
//   onUpdateCustomer: PropTypes.func,
// };

export default Locations
