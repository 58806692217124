import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"

import classNames from "classnames"
import AxiosInstance from "services/axios_service"
import LineApexChart from "../AllCharts/apex/chartapex"

import DateRangePicker from "components/calendar/DateRangePicker"

//import Charts
import StackedColumnChart from "./StackedColumnChart"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

import modalimage1 from "../../assets/images/product/img-7.png"
import modalimage2 from "../../assets/images/product/img-4.png"

// Pages Components
import WelcomeComp from "./WelcomeComp"
import MonthlyEarning from "./MonthlyEarning"
import SocialSource from "./SocialSource"
import ActivityComp from "./ActivityComp"
import TopCities from "./TopCities"
// import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"

const Dashboard = props => {
  const [statsData, setStatsData] = useState(null)
  const [statsCards, setStatsCards] = useState(null)
  const [chartDetails, setChartDetails] = useState({})
  const [filters, setFilters] = useState({
    startDate: localStorage.getItem("startDate"),
    endDate: localStorage.getItem("endDate"),
  })
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))
  const [isDateSelected, setIsDateSelected] = useState(false)
  const [showRangeCalender, setShowRangeCalender] = useState(false)
  let calenderRef = useRef(false)

  useEffect(() => {
    AxiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/api/admin/stats/dashboard`,
      {
        params: { ...filters },
      }
    )
      .then(response => {
        console.log("STATS RESPONSE DATA ####", response.data)
        const data = [
          {
            title: "Total Customers",
            description: response.data?.data?.users_count?.customers_count,
          },
          {
            title: "Total Hosts",
            description: response.data?.data?.users_count?.hosts_count,
          },
        ]
        setStatsData(data)
        setChartDetails({
          data: response.data?.data?.users_count?.group_by_data?.map(d => {
            return {
              name: d.name,
              data: d.count,
            }
          }),
          daysArray: response.data?.data?.days,
          title: "Mix Chart",
          xAxisTitle: "Dates",
          yAxisTitle: "Counts",
        })
      })

      .catch()
  }, [filters])

  document.title = "Dashboard | Measure - Admin & Dashboard Template"
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Measure" breadcrumbItem={props.t("Dashboard")} />
          <Row>
            <Col md="9"></Col>

            <Col md="3" className="mb-2">
              <Input
                // fontWeight='80'
                // mb={2}
                // variant='main'
                value={
                  isDateSelected
                    ? `${localStorage.getItem(
                        "start"
                      )} ~ ${localStorage.getItem("end")}`
                    : "Select Date"
                }
                readOnly={true}
                h="44px"
                maxh="44px"
                onClick={() => {
                  calenderRef.current = showRangeCalender
                  setShowRangeCalender(!showRangeCalender)
                }}
              />
              {showRangeCalender && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 2,
                    right: 12,
                    padding: 6,
                    background: "white",
                    boxShadow: "5px 5px 5px 10px #F5F5F8",
                    borderRadius: "12px",
                  }}
                >
                  <DateRangePicker
                    setFilters={setFilters}
                    filters={filters}
                    isDateSelected={isDateSelected}
                    setIsDateSelected={setIsDateSelected}
                  />
                </div>
              )}
            </Col>
          </Row>

          <Row className="m-3 flex justify-center items-center w-full ">
            <Col lg={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Line with Data Labels</CardTitle> */}
                  <LineApexChart
                    chartData={chartDetails}
                    dataColors='["--bs-primary", "--bs-success"]'
                  />
                </CardBody>
              </Card>
            </Col>
            {statsData &&
              statsData.map((statsData, key) => (
                <Col md="4" key={"_col_" + key}>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">
                            {statsData.title}
                          </p>
                          <h4 className="mb-0">{statsData.description}</h4>
                        </div>
                        <UncontrolledTooltip
                          placement="top"
                          target={"_col_" + key}
                        >
                          {statsData.title}
                        </UncontrolledTooltip>
                        <div
                          className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon"
                          id={"_col_" + key}
                        >
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={
                                "mdi " +
                                "mdi-information-variant" +
                                " font-size-24"
                                // "bx " + report.iconClass + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </>
  )
}

export default withTranslation()(Dashboard)
