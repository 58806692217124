export const getLocalAccessToken = () => localStorage.getItem('admin-token')

export const updateLocalAccessToken = (accessToken) => {
    localStorage.setItem('admin-token', accessToken)
}

export const clearLocalStorage = () => {
    localStorage.removeItem('admin-token');
    localStorage.removeItem('admin-name');
    localStorage.removeItem('admin-id');
}
