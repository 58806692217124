export const readableDate = date => {
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }
  return new Date(date).toLocaleString("en-US", options)
}
